import { UserSensorKind, ShippingOrderIncludable } from 'types'

export const SensorKindProductName = {
  [UserSensorKind.Libre1]: 'FreeStyle Libre 14 Day Sensor',
  [UserSensorKind.Libre2]: 'FreeStyle Libre 2 Sensor',
  [UserSensorKind.Libre3]: 'FreeStyle Libre 3 Sensor',
  [UserSensorKind.DexcomG7]: 'Research Dexcom G7 Sensor',
  [UserSensorKind.PrescriptionDexcomG7]: 'Prescription Dexcom G7 Sensor'
}

export enum ProductType {
  CGM = 'cgm',
  FreeReplacement = 'free_replacement',
  PaidReplacement = 'paid_replacement',
  Patch = 'patch',
  WelcomeKit = 'welcome_kit'
}

export const FORMATTED_PRODUCT_TYPE = {
  [ProductType.CGM]: 'CGM',
  [ProductType.FreeReplacement]: 'Free Replacement',
  [ProductType.PaidReplacement]: 'Paid Replacement',
  [ProductType.Patch]: 'Patch',
  [ProductType.WelcomeKit]: 'Welcome Kit'
}

export const FORMATTED_INCLUDABLE = {
  [ShippingOrderIncludable.Regular]: 'Regular',
  [ShippingOrderIncludable.WelcomeKit]: 'Welcome Kit'
}
