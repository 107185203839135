import { Switch } from '@mui/material'
import { useGridApiContext } from '@mui/x-data-grid'
import { IChangeValueGridInjectedProps } from 'screens/NutritionistsOrganization/NutritionistsGrid/ChangeManager'

export const ActiveSwitch = (props: IChangeValueGridInjectedProps) => {
  const { rowId, field } = props
  const apiRef = useGridApiContext()
  const value = apiRef.current.getCellValue(rowId, field)

  return (
    <Switch
      checked={value}
      value={value}
      onChange={(_event, newValue) => {
        apiRef.current.setEditCellValue({ id: rowId, field, value: newValue })
        apiRef.current.stopCellEditMode({ id: rowId, field })
      }}
    />
  )
}
