import { useState } from 'react'
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField
} from '@mui/material'
import { useGridApiContext } from '@mui/x-data-grid'
import { IModalProps } from 'hooks'
import { CloseIcon } from 'stream-chat-react'
import { AddressState } from 'types'

interface IChangeLicencesModalProps extends IModalProps {
  rowId: number
  userId: string
  field: string
}

export const ChangeLicencesModal = (props: IChangeLicencesModalProps) => {
  const { rowId, field, closeModal, modalState } = props

  const apiRef = useGridApiContext()
  const currentValue = apiRef.current.getCellValue(rowId, field)
  const [selectedStates, setSelectedStates] = useState(currentValue)

  const options = Object.values(AddressState).map((value) => value.toString())

  const onChange = (_: any, newValue: any) => {
    setSelectedStates(newValue)
  }

  const onCloseAction = () => {
    closeModal()
    apiRef.current.setEditCellValue({ id: rowId, field, value: selectedStates })
    apiRef.current.stopCellEditMode({ id: rowId, field })
  }

  return (
    <Dialog open={modalState} onClose={onCloseAction}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          State Licences
          <IconButton aria-label="close" onClick={onCloseAction}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Autocomplete
          multiple
          fullWidth
          value={selectedStates}
          options={options}
          ListboxProps={{ style: { minHeight: '95vh' } }} // needed for setting the popper height; setting it in componentsProps gets overwritten somehow
          componentsProps={{
            popper: {
              placement: 'auto-start',
              modifiers: [
                {
                  name: 'preventOverflow',
                  options: {
                    padding: 20
                  }
                },
                {
                  name: 'offset',
                  options: {
                    offset: [0, 8]
                  }
                }
              ],
              style: {
                width: 375
              }
            },
            paper: {
              style: {
                backgroundColor: '#FAFAFA',
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
              }
            }
          }}
          clearOnEscape
          onChange={onChange}
          renderInput={({ inputProps, ...rest }) => {
            return <TextField {...rest} inputProps={{ ...inputProps }} />
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onCloseAction}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  )
}
