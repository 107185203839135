import { FC } from 'react'
import { LoadingButton } from '@mui/lab'
import { Avatar, TextField } from '@mui/material'
import { AuthorizedFeature } from 'components'
import { format } from 'date-fns'
import { capitalize } from 'lodash'
import { StrongTypographyStyled } from 'styles'
import { FlexBoxStyled } from 'utility/box'
import { formatWithSubscriptionStatusMask } from 'utility/formatWithSubscriptionStatusMask'
import { MMM_DD_HH_MM_A, MMM_DD_YYYY } from 'utility/timeFormats'
import { HealthPortalFeature, useCurrentUserQuery, useInvalidateRefillConsentMutation } from 'types'
import { getAddressText } from './helpers/getAddressText'
import { ProfileGridStyled } from './styles'

interface IProfileForm {
  email: string
  firstName: string
  lastName: string
  phoneNumber: string
  sex: string
  address?: string
  approvalRequestState: string
  sensorCount: number
  sensorModel: string
  sensorSerialNumber: string
  sensorReplacementCount: number
  sensorKind: string
  dietitianSubscriptionStatus: string
  coach: string
  coreSubscriptionPlanName: string
  lastAppVersion: string
  lastAppPlatform: string
  currentCommitment: number
  organizationName: string
  role: string
  refillConsentGivenAt: string
}

const RenderForm: FC<{ values: IProfileForm }> = ({ values }) => (
  <form>
    <ProfileGridStyled>
      <TextField label="Email" value={values.email} disabled />
      <TextField label="First Name" value={values.firstName} disabled />
      <TextField label="Last Name" value={values.lastName} disabled />
      <TextField label="Phone Number" value={values.phoneNumber} disabled />
      <TextField label="Sex" value={values.sex} disabled />
      <TextField label="Address" value={values.address} disabled multiline />
      <TextField label="Sensor Count" value={values.sensorCount} disabled />
      <TextField label="Active Sensor Type" value={values.sensorModel} disabled />
      <TextField label="Sensor Serial Number" value={values.sensorSerialNumber} disabled />
      <TextField label="Sensor Replacement Count" value={values.sensorReplacementCount} disabled />
      <TextField label="Organization" value={values.organizationName} disabled />
      <TextField label="App Version" value={values.lastAppVersion} disabled />
      <TextField label="Operating System" value={values.lastAppPlatform} disabled />
      <AuthorizedFeature feature={HealthPortalFeature.UsersViewFulfillmentConfiguration}>
        <TextField label="Sensor Kind Preference" value={values.sensorKind} disabled />
        <TextField
          label="Nutritionist Subscription Status"
          value={formatWithSubscriptionStatusMask(values.dietitianSubscriptionStatus)}
          disabled
        />
        <TextField
          label="Core Subscription Plan Name"
          value={values.coreSubscriptionPlanName}
          disabled
        />
        <TextField
          label="Current Commitment"
          value={`${values.currentCommitment} month${(values.currentCommitment > 1 && 's') || ''}`}
          disabled
        />
        <TextField label="Role" value={values.role} disabled />
        <TextField label="Refill Consent Given At" value={values.refillConsentGivenAt} disabled />
      </AuthorizedFeature>
    </ProfileGridStyled>
  </form>
)

export const Profile = () => {
  const { data } = useCurrentUserQuery()
  const [invalidateRefillConsent, { loading }] = useInvalidateRefillConsentMutation({
    refetchQueries: ['currentUser']
  })
  const user = data?.currentUser
  const avatar = user?.avatar || ''
  const dob = user?.dateOfBirth

  const sensorReplacementCount = user?.sensorInfo?.replacementRequestsCount
  const coreSubscriptionPlanName = user?.ongoingCoreSubscription?.plans[0]?.nickname

  if (!user) {
    return null
  }
  const userAddress = user.address ? { address: getAddressText(user.address) } : {}

  const userRefillConsentGivenAt = user.activeRefillConsent?.givenAt

  const onInvalidateRefillConsentClick = async () => {
    await invalidateRefillConsent()
  }

  const initialValues = {
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    phoneNumber: user.phoneNumber || '',
    sex: user.sex || '',
    ...userAddress,
    approvalRequestState: user?.lastPrescriptionApprovalRequest?.state || '',
    sensorCount: user?.sensorInfo?.totalCount || 0,
    sensorModel: user?.sensor?.model || '',
    sensorSerialNumber: user?.sensor?.serialNumber || '',
    sensorReplacementCount: sensorReplacementCount || 0,
    sensorKind: capitalize(user?.fulfillmentConfiguration?.sensorKind) || '',
    dietitianSubscriptionStatus: formatWithSubscriptionStatusMask(
      user?.lastDietitianSubscription?.stripeStatus
    ),
    coach: user?.lastAsyncChatAssignment?.coach?.fullName || '',
    coreSubscriptionPlanName: coreSubscriptionPlanName || '',
    lastAppVersion: user?.lastAppVersion || '',
    lastAppPlatform: user?.lastAppPlatform || '',
    currentCommitment: user?.ongoingDietitianSubscription?.paidMonthsCount || 0,
    organizationName: user?.organization?.name || '',
    role: user?.role || '',
    refillConsentGivenAt: userRefillConsentGivenAt
      ? format(new Date(userRefillConsentGivenAt), MMM_DD_HH_MM_A)
      : ''
  }

  return (
    <FlexBoxStyled flexDirection="column" alignItems="center">
      <Avatar sx={{ width: 96, height: 96, mb: 2 }} src={avatar} alt={user.fullName} />
      {dob && (
        <StrongTypographyStyled variant="subtitle2" mb={2}>{`DOB: ${format(
          new Date(dob),
          MMM_DD_YYYY
        )}`}</StrongTypographyStyled>
      )}
      <RenderForm values={initialValues} />
      {userRefillConsentGivenAt && (
        <AuthorizedFeature feature={HealthPortalFeature.UsersEditProfile}>
          <LoadingButton
            loading={loading}
            onClick={onInvalidateRefillConsentClick}
            sx={{ mt: 2 }}
            variant="contained"
            color="warning"
          >
            Invalidate refill consent
          </LoadingButton>
        </AuthorizedFeature>
      )}
    </FlexBoxStyled>
  )
}
