import { useReactiveVar } from '@apollo/client'
import {
  Dialog,
  DialogTitle,
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Tooltip
} from '@mui/material'
import { NoDataMessage } from 'components'
import { EmptyFieldChip } from 'components/EmptyFieldChip'
import { planDetailsDialogVisibleVar } from 'context'
import { startCase } from 'lodash'
import { applyNutritionistMask } from 'utility/applyNutritionistMask'
import { tooltipLookup } from 'utility/coachEndDateDescriptor'
import { formatWithSubscriptionStatusMask } from 'utility/formatWithSubscriptionStatusMask'
import { IUser, useCurrentUserQuery } from 'types'
import { formatDate, getCoachDates } from './helpers'

const DIALOG_TITLE = 'Plans Details'

const fieldToFormatter: { [key: string]: undefined | typeof startCase } = {
  coach_assignment_state: startCase,
  core_subscription_status: startCase,
  core_subscription_charge_status: startCase
}

export const PlansDetails = () => {
  const { data: { currentUser } = {} } = useCurrentUserQuery()
  const planDetailsOpen = useReactiveVar(planDetailsDialogVisibleVar)

  return (
    <Dialog open={planDetailsOpen} onClose={() => planDetailsDialogVisibleVar(false)}>
      <DialogTitle>{DIALOG_TITLE}</DialogTitle>
      <Paper sx={{ padding: 4, paddingTop: 2, width: 500, height: 300 }}>
        <TableContainer>
          <Table size="small">
            {currentUser ? (
              <TableBody>{renderCurrentUserRows(currentUser as IUser)}</TableBody>
            ) : (
              <NoDataMessage />
            )}
          </Table>
        </TableContainer>
      </Paper>
    </Dialog>
  )
}

const renderCurrentUserRows = (currentUser?: IUser) => {
  const { coachStartDate, coachResumesDate, coachEndDate, coachEndDateDescription } =
    getCoachDates(currentUser)

  return (
    <>
      {renderRow(formatDate(coachStartDate), 'coach_start_date')}
      {renderRow(
        formatDate(coachEndDate) || coachEndDateDescription,
        'coach_end_date',
        coachEndDate
          ? undefined
          : tooltipLookup[coachEndDateDescription as keyof typeof tooltipLookup]
      )}
      {renderRow(currentUser?.lastAsyncChatAssignment?.state, 'coach_assignment_state')}
      {coachResumesDate && renderRow(formatDate(coachResumesDate), 'coach_resumes_date')}
      {renderRow(
        formatWithSubscriptionStatusMask(currentUser?.lastCoreSubscription?.stripeStatus),
        'core_subscription_status'
      )}
      {renderRow(
        currentUser?.lastCoreSubscription?.latestInvoice?.latestCharge?.status,
        'core_subscription_charge_status'
      )}
      {renderRow(
        formatDate(currentUser?.lastCoreSubscription?.latestInvoice?.latestCharge?.created),
        'core_subscription_charge_created'
      )}
      {renderRow(currentUser?.lastAsyncChatAssignment?.coach?.fullName, 'coach')}
    </>
  )
}

const renderRow = (value: any, key: string, tooltip?: string) => {
  let formattedValue = fieldToFormatter[key]?.(value) || value

  const valueCell = (
    <TableCell>{formattedValue?.toString() || <EmptyFieldChip size="small" />}</TableCell>
  )

  return (
    <TableRow key={`${key}-${formattedValue}`}>
      <TableCell>{startCase(applyNutritionistMask(key))}</TableCell>
      {tooltip ? <Tooltip title={tooltip}>{valueCell}</Tooltip> : valueCell}
    </TableRow>
  )
}
