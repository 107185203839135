import { startCase } from 'lodash'
import { formatRelativeWithEnhancedLocale } from 'screens/AdminPanel/Notifications/helpers/formatRelativeWithEnhancedLocale'
import {
  CoachAssignmentKind,
  HealthPortalNotificationKind,
  IHealthPortalNotification,
  Maybe
} from 'types'

export type NotificationTitleProps = {
  returningMember?: boolean
  assignmentKind?: Maybe<CoachAssignmentKind> | undefined
}

export const COACH_NOTIFICATION_DATA = {
  [HealthPortalNotificationKind.NewComplimentaryAddOn]: {
    title: ({ assignmentKind, returningMember }: NotificationTitleProps) =>
      `🎉 ${returningMember ? 'Returning' : 'New'} ${
        assignmentKind ? startCase(assignmentKind) : ''
      } Member Assignment`,
    subtitle: (_: IHealthPortalNotification) =>
      'has started a new subscription with Nutritionist Support, and you have been assigned'
  },
  [HealthPortalNotificationKind.NewPaidAddOn]: {
    title: (_: unknown) => '🛒 Existing member status upgrade',
    subtitle: (_: IHealthPortalNotification) =>
      'signed up for the unlimited paid support add-on, and you have been assigned'
  },
  [HealthPortalNotificationKind.Recurring_12Weeks]: {
    title: (_: unknown) => '🎉  New Bundle Program Member',
    subtitle: (_: IHealthPortalNotification) =>
      'in the Recurring 12 Weeks Bundle Program has been assigned to you.'
  },
  [HealthPortalNotificationKind.Fixed_8Weeks]: {
    title: (_: unknown) => '🎉  New Bundle Program Member',
    subtitle: (_: IHealthPortalNotification) =>
      'in the Fixed 8 Weeks Bundle Program has been assigned to you.'
  },
  [HealthPortalNotificationKind.AnnualMembership]: {
    title: (_: unknown) => '🎉 Annual Membership Notifications',
    subtitle: (_: IHealthPortalNotification) =>
      'paid for Nutritionist Support as a part of the Annual Membership Program, and you have been assigned.'
  },
  [HealthPortalNotificationKind.Canceled]: {
    title: (_: unknown) => '❌ Cancelation Alert',
    subtitle: (notification: IHealthPortalNotification) => {
      const coachEndDate = formatRelativeWithEnhancedLocale(
        notification?.coachAssignment?.user?.ongoingDietitianSubscription?.cancelAt
      )
      return `has canceled their nutritionist support. ${
        coachEndDate ? `Nutritionist End Date is now ${coachEndDate}` : ''
      }`
    }
  },
  [HealthPortalNotificationKind.Paused]: {
    title: (_: unknown) => '⏸️ Member Subscription Pause',
    subtitle: () => 'has paused their nutrition support.'
  },
  [HealthPortalNotificationKind.Resumed]: {
    title: (_: unknown) => '▶️ Member Subscription Resume',
    subtitle: () => 'has resumed their nutrition support.'
  },
  [HealthPortalNotificationKind.RetentionTest]: {
    title: (_: unknown) => '🎉 Retention Member',
    subtitle: (_: IHealthPortalNotification) =>
      'has been assigned to you, and is part of a retention test.'
  },
  [HealthPortalNotificationKind.CoachEndDateChanged]: {
    title: (_: unknown) => '📅 Nutritionist End Date Change',
    subtitle: (notification: IHealthPortalNotification) => {
      const coachEndDate = formatRelativeWithEnhancedLocale(
        notification?.coachAssignment?.user?.ongoingDietitianSubscription?.cancelAt
      )

      return `has a change to their Nutritionist End Date. ${
        coachEndDate ? `New end date is ${coachEndDate}` : ''
      }`
    }
  },
  [HealthPortalNotificationKind.CoachingProgram]: {
    title: (_: unknown) => '🎉 Nutrition Coaching Program (NCP)',
    subtitle: (_: IHealthPortalNotification) =>
      'has subscribed to the Nutrition Coaching Program (NCP), and you have been assigned'
  },
  [HealthPortalNotificationKind.CoachChange]: {
    title: (_: unknown) => '🎉 Nutritionist Change',
    subtitle: (_: IHealthPortalNotification) =>
      'has been reassigned to you from another nutritionist.'
  },
  [HealthPortalNotificationKind.PremiumInitialSent]: {
    title: (_: unknown) => '✉️ Premium Initial sent',
    subtitle: (_: IHealthPortalNotification) =>
      'received automatically sent Premium Initial message'
  },
  [HealthPortalNotificationKind.AutomaticInitialSent]: {
    title: (_: unknown) => '✉️ Automatic Initial Message sent',
    subtitle: (_: IHealthPortalNotification) => 'received automatically sent Initial message'
  },
  [HealthPortalNotificationKind.MonthlyWrapUpSent]: {
    title: (_: unknown) => '✉️ Monthly Wrap Up sent',
    subtitle: (_: IHealthPortalNotification) =>
      'received automatically sent Monthly Wrap Up message'
  },
  [HealthPortalNotificationKind.SupportConversationAssigned]: {
    title: (_: unknown) => '💬 Support conversation assigned',
    subtitle: (_: IHealthPortalNotification) =>
      'started a Support Conversation and you have been assigned'
  },
  [HealthPortalNotificationKind.VideoCallScheduled]: {
    title: ({ assignmentKind }: NotificationTitleProps) => {
      const kindDescription = assignmentKind ? ` (${startCase(assignmentKind)})` : ''
      return `📽️ New video call scheduled${kindDescription} `
    },
    subtitle: (notification: IHealthPortalNotification) => {
      const appointmentDate = notification?.coachAssignment?.appointment?.date
      const formattedDate = appointmentDate
        ? formatRelativeWithEnhancedLocale(appointmentDate)
        : null
      return `scheduled a video call${formattedDate ? ` for ${formattedDate}` : ''}`
    }
  },
  [HealthPortalNotificationKind.InsuranceConfirmed]: {
    title: (_: unknown) => '🎉️ Member insurance confirmed',
    subtitle: (_: IHealthPortalNotification) =>
      'provided insurance details and it has been confirmed'
  },
  [HealthPortalNotificationKind.InsuranceConfirmedWithPatientResponsibility]: {
    title: (_: unknown) => '🎉️ Member insurance confirmed (with patient responsibility)',
    subtitle: (_: IHealthPortalNotification) =>
      'provided insurance details and it has been confirmed, but requires patient responsibility'
  },
  [HealthPortalNotificationKind.InsuranceRejected]: {
    title: (_: unknown) => '❌️ Member insurance rejected',
    subtitle: (_: IHealthPortalNotification) =>
      'provided insurance details and it has been rejected after benefits checks'
  },
  [HealthPortalNotificationKind.MidpointPulseSubmitted]: {
    title: (_: unknown) => '🎉️ Midpoint Pulse Survey Submitted',
    subtitle: (_: IHealthPortalNotification) => 'submitted their midpoint pulse survey'
  },
  [HealthPortalNotificationKind.VideoCallFeedbackSubmitted]: {
    title: (_: unknown) => '🎉️ Video Call Feedback Submitted',
    subtitle: (_: IHealthPortalNotification) => 'submitted their video call feedback survey'
  },
  [HealthPortalNotificationKind.DietitianMenuAssigned]: {
    title: (_: unknown) => 'Dietitian Menu Assignment created',
    subtitle: (_: IHealthPortalNotification) =>
      'bought a dietitian menu product and you have been assigned'
  }
}

export const SUPPORT_TICKET_NOTIFICATION_DATA = {
  [HealthPortalNotificationKind.TicketStatusChanged]: {
    title: (_: unknown) => 'Support Ticket Status Change',
    subtitle: (notification: IHealthPortalNotification) =>
      `The status for the support ticket with ID: ${notification?.supportTicket?.id} has changed`
  },
  [HealthPortalNotificationKind.TicketCommentCreated]: {
    title: (_: unknown) => 'New Comment for Ticket',
    subtitle: (notification: IHealthPortalNotification) =>
      `A new comment was left for the support ticket with ID: ${notification?.supportTicket?.id} `
  },
  [HealthPortalNotificationKind.TicketCommentSubscribed]: {
    title: (_: unknown) => 'Subscribed to ticket comments',
    subtitle: (notification: IHealthPortalNotification) =>
      `You have been subscribed to the comment board for the ticket with ID: ${notification?.supportTicket?.id} `
  }
}

export const NOTIFICATION_DATA: {
  [key in HealthPortalNotificationKind]: {
    title: ({ returningMember, assignmentKind }: NotificationTitleProps) => string
    subtitle: (notification: IHealthPortalNotification) => string
  }
} = {
  ...COACH_NOTIFICATION_DATA,
  ...SUPPORT_TICKET_NOTIFICATION_DATA
}
