import {
  UserDynamicFilterField,
  DynamicFilterItemOperation,
  DynamicFilterOperator,
  UserRole
} from 'types'

export const nutritionistsRoleFilter = {
  field: UserDynamicFilterField.Role,
  operation: DynamicFilterItemOperation.In,
  value: [UserRole.Nutritionist, UserRole.NutritionistManager].join(',')
}

export const getAllNutritionistManagersFilters = () => {
  return {
    dynamicFilters: [
      {
        items: [
          {
            field: UserDynamicFilterField.Role,
            operation: DynamicFilterItemOperation.Eq,
            value: UserRole.NutritionistManager
          }
        ],
        operator: DynamicFilterOperator.And
      }
    ]
  }
}
