import { buildVars } from 'context/gridVars'
import { getNutritionistsViewColumns } from 'screens/NutritionistsOrganization/NutritionistsGrid/constants/columns'
import { NUTRITIONISTS_DATA_GRID_STATE_KEY } from 'screens/NutritionistsOrganization/NutritionistsGrid/constants/constants'

const nutritionistsViewColumns = getNutritionistsViewColumns()

export const nutritionistsGridVars = buildVars(
  nutritionistsViewColumns,
  NUTRITIONISTS_DATA_GRID_STATE_KEY
)
