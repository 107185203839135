import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'
import { Badge } from '@mui/material'
import { HealthPortalNotificationKind } from 'types'
import { useCurrentUserUnreadSurveyNotifications } from './hooks/useCurrentUserUnreadSurveyNotifications'

export const QuestionnaireSidebarIcon = () => {
  const unreadSurveyNotificationsForCurrentUser = useCurrentUserUnreadSurveyNotifications({
    kinds: [HealthPortalNotificationKind.VideoCallFeedbackSubmitted]
  })

  return (
    <Badge badgeContent={unreadSurveyNotificationsForCurrentUser.length} color="primary">
      <AssignmentOutlinedIcon />
    </Badge>
  )
}
