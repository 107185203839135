import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import { ColumnHeaderTableCell, RowLabelTableCell, TableCellStyled } from './styles'

interface IAvailabilityTable {
  data: {
    [key: string]: {
      [key: string]: any
    }
  }
}

export const AvailabilityTable = ({ data }: IAvailabilityTable) => {
  const timestamps = Object.keys(data).sort()

  const states = Object.keys(data[timestamps[0]])

  const index = states.indexOf('Total')

  if (index > -1) {
    states.splice(index, 1)
    states.unshift('Total')
  }

  return (
    <TableContainer component={Paper} sx={{ maxHeight: 1000, overflow: 'auto' }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell />
            {timestamps.map((timestamp) => (
              <ColumnHeaderTableCell key={timestamp}>{timestamp}</ColumnHeaderTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {states.map((state) => (
            <TableRow key={state}>
              <RowLabelTableCell>{state}</RowLabelTableCell>
              {timestamps.map((timestamp) => {
                const value = data[timestamp][state]
                return (
                  <TableCellStyled key={timestamp} value={value}>
                    {value}
                  </TableCellStyled>
                )
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
