import { getGridSingleSelectOperators } from '@mui/x-data-grid'
import { ManagerFilter } from 'screens/NutritionistsOrganization/NutritionistsGrid/ManagerFilter'

export const managerFilterOperators = () =>
  getGridSingleSelectOperators()
    .filter((operator) => operator.value === 'is')
    .map((operator) => ({
      ...operator,
      InputComponent: ManagerFilter
    }))
