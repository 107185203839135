import { ISurveyLink, SurveysConfigKind } from 'types'

export const latestSurveyOfKind = (kind: SurveysConfigKind, surveyLinks?: ISurveyLink[]) =>
  (surveyLinks || [])
    .filter((surveyLink) => surveyLink.survey.kind === kind)
    .sort((a, b) => {
      const dateA = a.finishedAt ? new Date(a.finishedAt).getTime() : 0
      const dateB = b.finishedAt ? new Date(b.finishedAt).getTime() : 0
      return dateB - dateA
    })[0]
