import { useModal } from 'hooks'
import { IChangeValueGridInjectedProps } from 'screens/NutritionistsOrganization/NutritionistsGrid/ChangeManager'
import { ChangeBioModal } from './ChangeBioModal'

export const ChangeBio = (props: IChangeValueGridInjectedProps) => {
  const { rowId, field, userId } = props

  const { closeModal: closeChangeBioModal } = useModal()

  return (
    <ChangeBioModal
      rowId={rowId}
      field={field}
      userId={userId}
      closeModal={closeChangeBioModal}
      modalState
    />
  )
}
