import { useGridApiContext } from '@mui/x-data-grid'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { parseISO, toDate } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import { IChangeValueGridInjectedProps } from 'screens/NutritionistsOrganization/NutritionistsGrid/ChangeManager'

export const Datepicker = (props: IChangeValueGridInjectedProps) => {
  const { rowId, field } = props
  const apiRef = useGridApiContext()
  const currentValue = apiRef.current.getCellValue(rowId, field)

  return (
    <DesktopDatePicker
      format="MMM d, yyyy"
      value={currentValue ? toDate(parseISO(currentValue)) : null}
      slotProps={{
        actionBar: {
          actions: ['clear']
        }
      }}
      onChange={(newValue) => {
        if (newValue) {
          apiRef.current.setEditCellValue({
            id: rowId,
            field,
            value: zonedTimeToUtc(Date.parse(newValue.toString()), 'UTC')
          })
        } else {
          apiRef.current.setEditCellValue({ id: rowId, field, value: null })
        }
        apiRef.current.stopCellEditMode({ id: rowId, field })
      }}
    />
  )
}
