import { Box, Card, TableCell } from '@mui/material'
import styled from '@mui/styled-engine'
import { theme } from 'theme'

export const BoxStyled = styled(Box)({
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: theme.palette['theme-primary']['level-1'],
  borderRadius: 1,
  padding: 2
})

export const CardStyled = styled(Card)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 10,
  padding: 2,
  borderRadius: 10
})

export const RowLabelTableCell = styled(TableCell)({
  position: 'sticky',
  left: 0,
  backgroundColor: 'white',
  zIndex: 1,
  fontWeight: 'bold'
})

export const ColumnHeaderTableCell = styled(TableCell)({
  position: 'sticky',
  top: 0,
  backgroundColor: 'white',
  zIndex: 2,
  fontWeight: 'bold'
})

export const TableCellStyled = styled(TableCell)(({ value }: { value: number }) => ({
  backgroundColor:
    value <= 0
      ? theme.palette['danger-transparent'].main
      : value <= 2
      ? theme.palette['warning-transparent'].main
      : theme.palette['success-transparent'].main,
  align: 'center'
}))
