import { useState, useEffect, useMemo } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { AppBar, Box, Dialog, DialogContent, IconButton, Toolbar, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Loader } from 'components'
import { parseISO, format, isSameDay } from 'date-fns' // Helper functions from date-fns
import { YYYY_MM_DD } from 'utility/timeFormats'
import { useCoachAvailabilitySnapshotQuery } from 'types'
import { AvailabilityTable } from './AvailabilityTable'
import { formatSnapshotKey } from './utils'

interface IViewSnapshotDialog {
  snapshotKey?: string
  onClose: () => void
}

export function ViewSnapshotDialog({ snapshotKey, onClose }: IViewSnapshotDialog) {
  const { data } = useCoachAvailabilitySnapshotQuery({
    variables: { snapshotKey: snapshotKey! },
    skip: !snapshotKey,
    fetchPolicy: 'network-only'
  })

  const snapshotData = data?.coachAvailabilitySnapshot?.data

  const availableDates = useMemo(() => {
    return snapshotData ? Object.keys(snapshotData).map((date) => parseISO(date)) : []
  }, [snapshotData])

  const [selectedDate, setSelectedDate] = useState<Date | null>(null)

  useEffect(() => {
    if (availableDates.length > 0 && !selectedDate) {
      setSelectedDate(availableDates[0])
    }
  }, [availableDates, selectedDate])

  const tableDataForDate = useMemo(() => {
    if (!snapshotData || !selectedDate) return null

    const formattedDate = format(selectedDate, YYYY_MM_DD)
    return snapshotData[formattedDate]
  }, [selectedDate, snapshotData])

  const formattedSnapshotKey = snapshotKey ? formatSnapshotKey(snapshotKey) : null

  return (
    <Dialog fullScreen open={!!snapshotKey}>
      <AppBar position="relative" sx={{ marginBottom: 1 }} color="inherit" elevation={0}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}
      >
        <Box display="inline">
          <Typography variant="body1" component="span">
            View the snapshot of availability for a specific date. The snapshot was taken at{' '}
          </Typography>
          <Typography variant="body1" component="span" fontWeight="bold">
            {formattedSnapshotKey} Pacific Time
          </Typography>
        </Box>
        <DatePicker
          label="Select a date"
          value={selectedDate}
          onChange={(newDate) => setSelectedDate(newDate)}
          shouldDisableDate={(date) =>
            !availableDates.some((availableDate) => isSameDay(date, availableDate))
          }
        />
        {tableDataForDate ? <AvailabilityTable data={tableDataForDate} /> : <Loader />}
      </DialogContent>
    </Dialog>
  )
}
