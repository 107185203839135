import { HTMLAttributes, useMemo } from 'react'
import { Autocomplete, Box, TextField } from '@mui/material'
import { useGridApiContext } from '@mui/x-data-grid'
import { getAllNutritionistManagersFilters } from 'screens/NutritionistsOrganization/NutritionistsGrid/helpers/nutritionistsFilters'
import { IUser, useAllNutritionistsQuery } from 'types'

export type IAllManagersOption = {
  id: string
  fullName: string
}

export interface IChangeValueGridInjectedProps {
  rowId: number
  userId: string
  field: string
}

export const ChangeManager = (props: IChangeValueGridInjectedProps) => {
  const { rowId, field } = props

  const apiRef = useGridApiContext()
  const currentValue = apiRef.current.getCellValue(rowId, field)

  const { data: nutritionistManagers, loading } = useAllNutritionistsQuery({
    variables: getAllNutritionistManagersFilters()
  })

  const options: IAllManagersOption[] = useMemo(
    () => nutritionistManagers?.allUsers.users ?? [],
    [nutritionistManagers?.allUsers.users]
  )

  const onChange = (_: any, newManager: IUser) => {
    apiRef.current.setEditCellValue({ id: rowId, field, value: newManager })
    apiRef.current.stopCellEditMode({ id: rowId, field })
  }

  return (
    <Autocomplete
      autoSelect
      defaultValue={currentValue}
      fullWidth
      options={options}
      loading={loading}
      getOptionLabel={(user: IAllManagersOption) => user.fullName}
      isOptionEqualToValue={(option: IAllManagersOption, value: IUser) => option.id === value.id}
      renderOption={(
        props: HTMLAttributes<HTMLDivElement | HTMLLIElement>,
        user: IAllManagersOption
      ) => {
        const optionIndex = options.findIndex((manager) => manager.id === user.id)
        return (
          <Box {...props} key={`manager_option_${user.id}`}>
            <Box sx={{ marginX: 1 }}>{`${optionIndex + 1}.  ${user.fullName}`}</Box>
          </Box>
        )
      }}
      ListboxProps={{ style: { minHeight: '95vh' } }} // needed for setting the popper height; setting it in componentsProps gets overwritten somehow
      componentsProps={{
        popper: {
          placement: 'auto-start',
          modifiers: [
            {
              name: 'preventOverflow',
              options: {
                padding: 20
              }
            },
            {
              name: 'offset',
              options: {
                offset: [0, 8]
              }
            }
          ],
          style: {
            width: 375
          }
        },
        paper: {
          style: {
            backgroundColor: '#FAFAFA',
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
          }
        }
      }}
      clearOnEscape
      noOptionsText="No Manager was found"
      onChange={onChange}
      renderInput={({ inputProps, ...rest }) => {
        return <TextField {...rest} inputProps={{ ...inputProps }} />
      }}
    />
  )
}
