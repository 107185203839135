import { Switch } from '@mui/material'
import { getGridNumericOperators, GridEditInputCell, GridRenderCellParams } from '@mui/x-data-grid'
import { IDataGridColumn } from 'components/DataGrid/DataGrid'
import { format } from 'date-fns'
import { startCase } from 'lodash'
import { managerFilterOperators } from 'screens/NutritionistsOrganization/NutritionistsGrid/helpers/managerFilterOperators'
import { Datepicker } from 'screens/NutritionistsOrganization/NutritionistsGrid/index'
import {
  ChangeBio,
  ChangeLicences,
  ChangeManager,
  ActiveSwitch
} from 'screens/NutritionistsOrganization/NutritionistsGrid/index'
import { COLUMNS } from 'screens/NutritionistsOrganization/NutritionistsGrid/types'
import { renderAsCheck } from 'utility/dataGrid'
import { MMM_D_YYYY } from 'utility/timeFormats'
import { UserDynamicFilterField, UserRole, UserSortField } from 'types'

export type INutritionistsColumnsType = IDataGridColumn[]
export const getNutritionistsViewColumns = (): INutritionistsColumnsType => [
  {
    field: COLUMNS['#'],
    headerName: '#',
    type: 'number',
    width: 25,
    sortable: false,
    filterable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) =>
      params.api.getRowIndex(params.row.id) + 1
  },
  {
    field: COLUMNS.NAME,
    headerName: 'Name',
    width: 200,
    sortable: false,
    type: 'string',
    valueGetter: ({ row }) => row?.fullName
  },
  {
    field: COLUMNS.EMAIL,
    headerName: 'Email',
    valueGetter: ({ row }) => row?.email,
    width: 250,
    sortable: false
  },
  {
    field: COLUMNS.ROLE,
    headerName: 'Role',
    type: 'singleSelect',
    valueFormatter: ({ value }) => startCase(value || ''),
    valueOptions: Object.keys(UserRole).flatMap((key: keyof typeof UserRole) => {
      return [UserRole.Nutritionist, UserRole.NutritionistManager].includes(UserRole[key])
        ? { label: key, value: UserRole[key] }
        : []
    }),
    width: 250,
    backendFilterName: UserDynamicFilterField.Role,
    sortable: false
  },
  {
    field: COLUMNS.BIO,
    editable: true,
    filterable: false,
    headerName: 'Bio',
    valueGetter: ({ row }) => row?.bio,
    width: 250,
    renderEditCell: ({ row, field }) => {
      return <ChangeBio rowId={row?.id} userId={row?.id} field={field} />
    },
    sortable: false
  },
  {
    field: COLUMNS.HEALTHIE_ID,
    editable: true,
    headerName: 'Healthie ID',
    valueGetter: ({ row }) => row?.getHealthieId,
    valueParser: (value) => {
      const numericValue = value.replace(/[^0-9]/g, '')
      return numericValue === '' ? '' : numericValue
    },
    width: 250,
    type: 'string',
    filterOperators: getGridNumericOperators(),
    backendFilterName: UserDynamicFilterField.CoachProfileGetHealthieId,
    sortable: false
  },
  {
    field: COLUMNS.MANAGER,
    editable: true,
    headerName: 'Manager',
    valueGetter: ({ row }) => row.manager,
    width: 250,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return params.row.manager?.fullName
    },
    renderEditCell: ({ row, field }) => {
      return <ChangeManager rowId={row?.id} userId={row?.id} field={field} />
    },
    filterOperators: managerFilterOperators(),
    backendFilterName: UserDynamicFilterField.CoachProfileManagerId,
    sortable: false
  },
  {
    field: COLUMNS.START_DATE,
    editable: true,
    filterable: false,
    headerName: 'Start Date',
    valueGetter: ({ row }) =>
      row.startDate ? format(Date.parse(row.startDate.toString()), MMM_D_YYYY) : undefined,
    width: 250,
    renderEditCell: ({ row, field }) => {
      return <Datepicker rowId={row?.id} field={field} userId={row?.id} />
    },
    backendSortName: UserSortField.CoachProfileStartDate
  },
  {
    field: COLUMNS.END_DATE,
    editable: true,
    filterable: false,
    headerName: 'End Date',
    valueGetter: ({ row }) =>
      row.endDate ? format(Date.parse(row.endDate.toString()), MMM_D_YYYY) : undefined,
    width: 250,
    renderEditCell: ({ row, field }) => {
      return <Datepicker rowId={row?.id} field={field} userId={row?.id} />
    },
    backendSortName: UserSortField.CoachProfileEndDate
  },
  {
    field: COLUMNS.SCHEDULING_PRIORITY,
    editable: true,
    filterable: false,
    type: 'number',
    headerName: 'Scheduling Priority',
    renderEditCell: (params) => (
      <GridEditInputCell
        {...params}
        inputProps={{
          max: 5,
          min: 0
        }}
      />
    ),
    valueGetter: ({ row }) => row?.schedulingPriority,
    width: 250,
    sortable: false
  },
  {
    field: COLUMNS.CREDENTIALS,
    editable: true,
    filterable: false,
    type: 'string',
    headerName: 'Credentials',
    valueGetter: ({ row }) => row?.credentials,
    width: 250,
    sortable: false
  },
  {
    field: COLUMNS.STATE_LICENCES,
    editable: true,
    filterable: false,
    headerName: 'State Licences',
    valueGetter: ({ row }) => row?.stateLicences,
    width: 250,
    renderEditCell: ({ row, field }) => {
      return <ChangeLicences rowId={row?.id} userId={row?.id} field={field} />
    },
    sortable: false
  },
  {
    field: COLUMNS.ACTIVE,
    editable: false,
    filterable: false,
    headerName: 'Active',
    type: 'boolean',
    renderCell: renderAsCheck,
    valueGetter: ({ row }) => row?.active,
    width: 200,
    sortable: false
  },
  {
    field: COLUMNS.UPDATED_AT,
    editable: false,
    filterable: false,
    headerName: 'Updated at',
    width: 250,
    valueGetter: ({ row }) =>
      row.updatedAt ? format(Date.parse(row.updatedAt.toString()), MMM_D_YYYY) : undefined,
    sortable: false
  },
  {
    field: COLUMNS.PRIORITY_CHANGED_AT,
    editable: false,
    filterable: false,
    headerName: 'Priority changed at',
    width: 250,
    valueGetter: ({ row }) =>
      row.priorityChangedAt
        ? format(Date.parse(row.priorityChangedAt.toString()), MMM_D_YYYY)
        : undefined,
    sortable: false
  },
  {
    field: COLUMNS.OUT_OF_OFFICE,
    editable: true,
    filterable: false,
    headerName: 'Out of office',
    valueGetter: ({ row }) => row?.outOfOffice,
    width: 250,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      const value = params.value
      return <Switch checked={value} value={value} disabled />
    },
    renderEditCell: ({ row, field }) => {
      return <ActiveSwitch rowId={row?.id} userId={row?.id} field={field} />
    },
    sortable: false
  }
]
