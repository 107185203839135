import { BaseSyntheticEvent, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextareaAutosize
} from '@mui/material'
import { useGridApiContext } from '@mui/x-data-grid'
import { IModalProps } from 'hooks'
import { CloseIcon } from 'stream-chat-react'

interface IChangeBioModalProps extends IModalProps {
  rowId: number
  userId: string
  field: string
}

export const ChangeBioModal = (props: IChangeBioModalProps) => {
  const { rowId, field, closeModal, modalState } = props

  const apiRef = useGridApiContext()
  const currentValue = apiRef.current.getCellValue(rowId, field)
  const [currentBio, setCurrentBio] = useState(currentValue)

  const onChange = (event: BaseSyntheticEvent) => {
    setCurrentBio(event.target.value)
  }

  const onSaveAction = () => {
    closeModal()
    apiRef.current.setEditCellValue({ id: rowId, field, value: currentBio })
    apiRef.current.stopCellEditMode({ id: rowId, field })
  }

  const onCloseAction = () => {
    closeModal()
    apiRef.current.stopCellEditMode({ id: rowId, field })
  }

  return (
    <Dialog open={modalState} onClose={onCloseAction}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          Bio
          <IconButton aria-label="close" onClick={onCloseAction}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <TextareaAutosize defaultValue={currentBio} onChange={onChange} />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onSaveAction}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  )
}
