import {
  ForwardedRef,
  forwardRef,
  RefObject,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from 'react'
import { useReactiveVar } from '@apollo/client'
import {
  DataGridProps,
  GridFilterModel,
  GridLinkOperator,
  GridSortModel,
  GRID_NUMERIC_COL_DEF,
  GridFilterItem,
  GridState,
  GridSlotsComponent,
  GridEnrichedColDef,
  GridColumnTypesRecord
} from '@mui/x-data-grid'
import { GridDensityTypes } from '@mui/x-data-grid/models/gridDensity'
import { defaultFilterModel, GridVars } from 'context/gridVars'
import { last, pick } from 'lodash'
import { DataGridStyled, loadState, saveState } from 'utility/dataGrid'
import { DynamicFilterItemOperation, DynamicFilterOperator } from 'types'
import ColumnMenu from './ColumnMenu'
import { GroupedDataGrid } from './GroupedDataGrid'
import { getFiltersFromFilterItem } from './helpers/getFiltersFromFilterItem'
import { getSortByFromSortItem } from './helpers/getSortByFromSortItem'
import { isQuickFilterSelected } from './helpers/isQuickFilterSelected'
import Toolbar, { IToolbarProps } from './Toolbar'

export const DEFAULT_ROWS_PER_PAGE_OPTIONS = [5, 10, 25, 50, 100, 150, 250, 1000]
export const DEFAULT_PER_PAGE_VALUE = DEFAULT_ROWS_PER_PAGE_OPTIONS[4]

export type IDataGridColumn = GridEnrichedColDef & {
  backendFilterName?: string
  backendSortName?: string
}

type IDataGridProps = Pick<
  DataGridProps,
  | 'loading'
  | 'rows'
  | 'error'
  | 'processRowUpdate'
  | 'onProcessRowUpdateError'
  | 'rowCount'
  | 'getRowClassName'
  | 'isCellEditable'
  | 'columnVisibilityModel'
  | 'checkboxSelection'
> &
  Partial<Omit<IToolbarProps, 'quickFilters'>> & {
    gridVars: GridVars
    showResendPrescriptionsAction?: boolean
    rowHeight?: 'auto'
    stateKey?: string
    quickFilterDefinitions?: {
      name: string
      items: GridFilterItem[]
      linkOperator?: GridLinkOperator
    }[]
    linkOperators?: GridLinkOperator[]
    columns: IDataGridColumn[]
    rowsPerPageOptions?: number[]
    groupRowsPerPageOptions?: number[]
    onStateChange?: (state: GridState) => void
    autoHeight?: boolean
    noToolbar?: boolean
  } & Partial<GridSlotsComponent>

export type DataGridHandle = {
  filterModel: GridFilterModel
  setFilterModel: (filter: GridFilterModel) => void
}

const DataGrid = (props: IDataGridProps, ref?: ForwardedRef<DataGridHandle>) => {
  const {
    loading,
    rows,
    columns,
    error,
    processRowUpdate,
    onProcessRowUpdateError,
    rowCount,
    gridVars,
    showResendPrescriptionsAction,
    getMainFields,
    getTruepillPrescriptionFields,
    BulkUpdateSelectedRows,
    Pagination,
    bulkUpdate,
    bulkUpdateVisible,
    toolbarAdornments,
    rowHeight,
    refresh,
    getRowClassName,
    quickFilterDefinitions,
    stateKey,
    linkOperators = [GridLinkOperator.And, GridLinkOperator.Or],
    isCellEditable,
    rowsPerPageOptions,
    groupRowsPerPageOptions,
    additionalActions,
    onStateChange,
    autoHeight = false,
    noToolbar = false,
    columnVisibilityModel,
    checkboxSelection
  } = props
  const {
    filterModelVar,
    dynamicFilterOperatorVar,
    dynamicFiltersVar,
    pageVar,
    perPageVar,
    sortByVar,
    sortOrderVar,
    sortModelVar
  } = gridVars
  const perPage = useReactiveVar(perPageVar)
  const filterModel = useReactiveVar(filterModelVar)
  const sortModel = useReactiveVar(sortModelVar)

  const initialState = stateKey ? loadState(stateKey) : undefined

  const [rowCountState, setRowCountState] = useState(rowCount || 0)

  const [groupField, setGroupField] = useState<string | null>(null)

  const setGroupFieldAndIncreasePerPageVar = (value: string | null) => {
    perPageVar(last(groupRowsPerPageOptions || rowsPerPageOptions || DEFAULT_ROWS_PER_PAGE_OPTIONS))
    setGroupField(value)
  }

  useEffect(() => {
    setRowCountState((prevRowCountState) => (rowCount !== undefined ? rowCount : prevRowCountState))
  }, [rowCount, setRowCountState])

  const onSortModelChange = (model: GridSortModel) => {
    sortModelVar(model)
    const [sortItem] = model
    if (!sortItem) {
      return
    }

    sortByVar(getSortByFromSortItem(sortItem, columns))
    sortOrderVar(sortItem.sort)
  }

  const onFilterModelChange = (model: GridFilterModel) => {
    filterModelVar(model)

    const allFilters = model.items.flatMap(
      (item) => getFiltersFromFilterItem(item, columns) ?? []
    ) as { field: string; operation: DynamicFilterItemOperation; value: string }[]

    dynamicFiltersVar(allFilters)
    if (model.linkOperator) {
      dynamicFilterOperatorVar(model.linkOperator as unknown as DynamicFilterOperator)
    }
  }

  const resetFilterModel = () => {
    onFilterModelChange(defaultFilterModel)
  }

  useImperativeHandle(ref, () => ({
    filterModel,
    setFilterModel: onFilterModelChange
  }))

  const dataGridHandleRef = ref as RefObject<DataGridHandle>

  const selectedFilterItems = dataGridHandleRef?.current?.filterModel.items
  const quickFilters = useMemo(() => {
    return quickFilterDefinitions?.map(({ name, items, linkOperator }) => {
      return {
        name,
        onClick() {
          perPageVar(last(rowsPerPageOptions || DEFAULT_ROWS_PER_PAGE_OPTIONS))
          dataGridHandleRef?.current?.setFilterModel({
            linkOperator: linkOperator || GridLinkOperator.And,
            items
          })
        },
        isSelected: selectedFilterItems
          ? isQuickFilterSelected(selectedFilterItems, items, columns)
          : false
      }
    })
  }, [
    selectedFilterItems,
    columns,
    perPageVar,
    quickFilterDefinitions,
    dataGridHandleRef,
    rowsPerPageOptions
  ])

  const sharedProps = {
    // https://mui.com/x/react-data-grid/rows/#dynamic-row-height
    sx: {
      '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
      '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
      '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' }
    },
    getRowHeight: () => rowHeight,
    getEstimatedRowHeight: () => 52,
    columns: columns,
    density: initialState?.density?.value || GridDensityTypes.Standard,
    getRowClassName,
    initialState: pick(initialState, 'columns'),
    columnTypes: {
      // for some reason it's aligned to right by default
      number: { ...GRID_NUMERIC_COL_DEF, align: 'left', headerAlign: 'left' }
    } as GridColumnTypesRecord,
    isCellEditable
  }

  const effectiveRowsPerPageOptions =
    (groupField ? groupRowsPerPageOptions : rowsPerPageOptions) || DEFAULT_ROWS_PER_PAGE_OPTIONS

  if (!effectiveRowsPerPageOptions.includes(perPage)) perPageVar(DEFAULT_PER_PAGE_VALUE)

  const serverDataGrid = (
    <DataGridStyled
      {...sharedProps}
      experimentalFeatures={{ newEditingApi: true }}
      loading={loading}
      getRowId={(row) => row.id}
      rows={rows}
      autoHeight={autoHeight}
      components={{
        Toolbar: noToolbar ? undefined : Toolbar,
        ColumnMenu,
        Pagination
      }}
      componentsProps={{
        filterPanel: {
          linkOperators
        },
        toolbar: {
          quickFilters,
          groupField,
          availableGroupColumns: columns.filter((column) => column.groupable),
          setGroupField: setGroupFieldAndIncreasePerPageVar,
          resetFilterModel,
          showResendPrescriptionsAction,
          getMainFields,
          getTruepillPrescriptionFields,
          BulkUpdateSelectedRows,
          toolbarAdornments,
          bulkUpdate,
          bulkUpdateVisible,
          refresh,
          additionalActions
        }
      }}
      error={error}
      paginationMode="server"
      rowCount={rowCountState}
      pageSize={perPage}
      rowsPerPageOptions={effectiveRowsPerPageOptions}
      onPageChange={(page) => pageVar(page + 1)}
      onPageSizeChange={(perPage) => perPageVar(perPage)}
      sortingOrder={['asc', 'desc']}
      sortingMode="server"
      onSortModelChange={onSortModelChange}
      sortModel={sortModel}
      filterMode="server"
      filterModel={filterModel}
      columnVisibilityModel={columnVisibilityModel}
      checkboxSelection={checkboxSelection}
      onFilterModelChange={onFilterModelChange}
      processRowUpdate={processRowUpdate}
      onProcessRowUpdateError={onProcessRowUpdateError}
      onStateChange={(state: GridState) => {
        stateKey && saveState(stateKey, state)
        onStateChange && onStateChange(state)
      }}
    />
  )

  const groupColumn = columns.find((column) => column.field === groupField)
  if (groupColumn) {
    return (
      <GroupedDataGrid
        rows={rows}
        dataGrid={serverDataGrid}
        groupColumn={groupColumn}
        initialState={initialState}
        stateKey={stateKey}
        sharedProps={sharedProps}
      />
    )
  }

  return serverDataGrid
}

export default forwardRef(DataGrid)
