import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const useFetchDataOnLocationChange = (fetchFunction: () => void, pathname: string) => {
  const location = useLocation()

  const isActiveRoute = location.pathname === pathname

  useEffect(() => {
    if (isActiveRoute) {
      fetchFunction()
    }
  }, [fetchFunction, isActiveRoute])
}
