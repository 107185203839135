import { HTMLAttributes, useRef } from 'react'
import { Autocomplete, Box, TextField } from '@mui/material'
import { GridFilterInputValueProps } from '@mui/x-data-grid'
import { useDebouncedSearch } from 'hooks/useDebouncedSearch'
import { IUser, useAllNutritionistsLazyQuery } from 'types'
import { IAllManagersOption } from './ChangeManager'
import { getAllNutritionistManagersFilters } from './helpers/nutritionistsFilters'

const INPUT_PLACEHOLDER_KEY = 'nutrisense:nutritionists:managerFilter:inputPlaceholder'

export const ManagerFilter = ({ item, applyValue }: GridFilterInputValueProps) => {
  const searchValue = useRef('')

  const handleChange = (_: any, coach: IUser) => {
    applyValue({
      ...item,
      value: coach?.id
    })
    localStorage.setItem(INPUT_PLACEHOLDER_KEY, coach?.fullName || '')
  }

  const [searchUsers, { data, loading }] = useAllNutritionistsLazyQuery({
    variables: getAllNutritionistManagersFilters()
  })

  const { handleInputChange } = useDebouncedSearch(searchUsers, searchValue)
  const options = data?.allUsers.users ?? []

  return (
    <Autocomplete
      fullWidth
      options={options}
      getOptionLabel={(user: IUser) => user.fullName}
      isOptionEqualToValue={(userA, userB) => userA?.id === userB?.id}
      loading={loading}
      noOptionsText="No Nutritionist was found"
      onChange={handleChange}
      onInputChange={handleInputChange}
      renderOption={(
        props: HTMLAttributes<HTMLDivElement | HTMLLIElement>,
        user: IAllManagersOption
      ) => {
        return (
          <Box {...props} key={`manager_option_${user.id}`}>
            <Box sx={{ marginX: 1 }}>{user.fullName}</Box>
          </Box>
        )
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Value"
          variant="standard"
          placeholder={localStorage.getItem(INPUT_PLACEHOLDER_KEY) || ''}
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  )
}
