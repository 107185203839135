import {
  HealthPortalNotificationKind,
  useAllHealthPortalNotificationsQuery,
  useCurrentUserQuery
} from 'types'

export const useCurrentUserUnreadSurveyNotifications = ({
  kinds
}: {
  kinds: HealthPortalNotificationKind[]
}) => {
  const { data: currentUserData } = useCurrentUserQuery()
  const currentUser = currentUserData?.currentUser

  const { data: healthPortalNotificationData } = useAllHealthPortalNotificationsQuery({
    variables: {
      kinds,
      userIds: currentUser ? [currentUser.id] : undefined
    },
    skip: !currentUser
  })

  const surveyNotifications =
    healthPortalNotificationData?.allHealthPortalNotifications?.healthPortalNotifications || []
  const unreadSurveyNotificationsForCurrentUser = surveyNotifications.filter(
    (notification) => !notification.completedAt
  )

  return unreadSurveyNotificationsForCurrentUser
}
