import { Button, CircularProgress, Typography } from '@mui/material'
import { LocationPaths } from 'location.types'
import {
  NutritionistOrganizationResource,
  NutritionistOrganizationSnapshotSegments
} from 'nutritionistOrganizationResource.types'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useListCoachAvailabilitySnapshotsQuery } from 'types'
import { BoxStyled, CardStyled } from './styles'
import { ViewSnapshotDialog } from './ViewSnapshotDialog'

export const AvailabilitySnapshots = () => {
  const { data: availabilityData, loading } = useListCoachAvailabilitySnapshotsQuery()
  const snapshotKeys = availabilityData?.listCoachAvailabilitySnapshots.snapshotKeys || []
  const { snapshotKey } = useParams()
  const navigate = useNavigate()

  const handleSnapshotClick = (snapshotKey: string) => () => {
    navigate(
      generatePath(`./${NutritionistOrganizationSnapshotSegments.Snapshot}`, {
        snapshotKey
      })
    )
  }

  const onCloseSnapshotDialog = () =>
    navigate(
      `${LocationPaths.NutritionistsOrganization}/${NutritionistOrganizationResource.AvailabilitySnapshots}`
    )

  if (loading) {
    return (
      <BoxStyled>
        <CircularProgress sx={{ alignSelf: 'center' }} />
      </BoxStyled>
    )
  }

  return (
    <BoxStyled>
      <CardStyled>
        <Typography variant="h4">Availability Snapshots</Typography>
        <Typography variant="body1">
          Each button represents a point in time where we took a snapshot of the scheduling
          availability for the next week.
        </Typography>
        <Typography variant="body1">
          Clicking a button will show you the availability for that point in time.
        </Typography>
        {snapshotKeys.map((key, index) => (
          <Button key={index} size="large" variant="outlined" onClick={handleSnapshotClick(key)}>
            {key}
          </Button>
        ))}
      </CardStyled>
      <ViewSnapshotDialog snapshotKey={snapshotKey} onClose={onCloseSnapshotDialog} />
    </BoxStyled>
  )
}
