import { useRef, useState } from 'react'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckIcon from '@mui/icons-material/Check'
import { GridActionsCellItem, GridCellParams, GridRowParams } from '@mui/x-data-grid'
import { DataGridHandle, DataGrid as ServerDataGrid } from 'components/DataGrid'
import { snackBarVisibleVar } from 'context'
import { nutritionistsGridVars } from 'context/nutritionists'
import { isEmpty } from 'lodash'
import { getCommonListQueryVariables, useReactiveGridVars } from 'utility/dataGrid/commonVariables'
import {
  DynamicFilterOperator,
  EhrCoachState,
  IUser,
  useAllNutritionistsQuery,
  useBulkUpdateCoachProfilesMutation,
  useDeactivateCoachProfileMutation
} from 'types'
import { BulkUpdateNutritionists } from './BulkUpdateNutritionists'
import { NUTRITIONISTS_DATA_GRID_STATE_KEY, getNutritionistsViewColumns } from './constants'
import { nutritionistsRoleFilter } from './helpers/nutritionistsFilters'
import { COLUMNS } from './types/nutritionistsViewColumn.types'

const ROWS_PER_PAGE_OPTIONS = [5, 10, 25, 50, 100, 150, 250]

export const NutritionistsGrid = () => {
  const dataGridRef = useRef<DataGridHandle>(null)
  const reactiveGridVars = useReactiveGridVars(nutritionistsGridVars)

  const commonListQueryVariables = getCommonListQueryVariables(reactiveGridVars)
  const dynamicFilters = commonListQueryVariables['dynamicFilters']?.items || []

  const listQueryVariables = {
    ...commonListQueryVariables,
    dynamicFilters: {
      items: [...dynamicFilters, nutritionistsRoleFilter],
      operator: DynamicFilterOperator.And
    }
  }

  const {
    data: usersData,
    loading,
    error,
    refetch
  } = useAllNutritionistsQuery({
    variables: listQueryVariables
  })
  const initialData = usersData?.allUsers.users || []

  const [unsavedChanges, setUnsavedChanges] = useState(
    {} as {
      [userId: string]: {
        manager: IUser
        startDate: string
        endDate: string
        stateLicences: string[]
        getHealthieId: string
        schedulingPriority: number
        outOfOffice: boolean
        credentials: string
        bio: string
      }
    }
  )

  const updateUnsavedChanges = (
    userId: string,
    manager: IUser,
    startDate: string,
    endDate: string,
    stateLicences: string[],
    getHealthieId: string,
    schedulingPriority: number,
    outOfOffice: boolean,
    credentials: string,
    bio: string
  ) => {
    setUnsavedChanges((prev) => ({
      ...prev,
      [userId]: {
        manager,
        startDate,
        endDate,
        stateLicences,
        getHealthieId,
        schedulingPriority,
        outOfOffice,
        credentials,
        bio
      }
    }))
  }

  const resetUnsavedChanges = (userId: string) => {
    setUnsavedChanges((prev) => {
      const newUnsavedChanges = { ...prev }
      delete newUnsavedChanges[userId]
      return newUnsavedChanges
    })
  }

  const discardChanges = () => {
    setUnsavedChanges({})
  }

  const isCellEditable = ({ field }: GridCellParams<any, any>) => {
    return (
      field === COLUMNS.MANAGER ||
      field === COLUMNS.START_DATE ||
      field === COLUMNS.END_DATE ||
      field === COLUMNS.STATE_LICENCES ||
      field === COLUMNS.SCHEDULING_PRIORITY ||
      field === COLUMNS.HEALTHIE_ID ||
      field === COLUMNS.OUT_OF_OFFICE ||
      field === COLUMNS.CREDENTIALS ||
      field === COLUMNS.BIO
    )
  }

  const [bulkUpdateCoachProfiles, { loading: savingChanges }] = useBulkUpdateCoachProfilesMutation({
    onError: (error) =>
      snackBarVisibleVar({
        open: true,
        message: error.message
      }),
    onCompleted: async () => {
      await refetch()
      setUnsavedChanges({})
    }
  })

  const [deactivateCoachProfile, { loading: deactivatingCoachProfile }] =
    useDeactivateCoachProfileMutation({
      onError: (error) =>
        snackBarVisibleVar({
          open: true,
          message: error.message
        }),
      onCompleted: async () => {
        await refetch()
      }
    })

  const bulkSaveChanges = () => {
    bulkUpdateCoachProfiles({
      variables: {
        coachProfiles: Object.keys(unsavedChanges).map((id) => ({
          userId: id,
          managerId: unsavedChanges[id].manager?.id || null,
          startDate: unsavedChanges[id].startDate,
          endDate: unsavedChanges[id].endDate,
          stateLicences: unsavedChanges[id].stateLicences,
          getHealthieId: unsavedChanges[id].getHealthieId,
          schedulingPriority: unsavedChanges[id].schedulingPriority,
          outOfOffice: unsavedChanges[id].outOfOffice,
          credentials: unsavedChanges[id].credentials,
          bio: unsavedChanges[id].bio
        }))
      }
    })
  }

  const processRowUpdate = (newRow: any, _: any) => {
    const oldRow = initialData.find((nutritionist) => nutritionist.id === newRow.id)

    if (
      oldRow &&
      (oldRow.coachProfile?.manager?.id !== newRow.manager?.id ||
        oldRow.coachProfile?.startDate !== newRow.startDate ||
        oldRow.coachProfile?.endDate !== newRow.endDate ||
        oldRow.coachProfile?.stateLicences !== newRow.stateLicences ||
        oldRow.coachProfile?.getHealthieId !== newRow.getHealthieId ||
        oldRow.coachProfile?.schedulingPriority !== newRow.schedulingPriority ||
        oldRow.coachProfile?.outOfOffice !== newRow.outOfOffice ||
        oldRow.coachProfile?.credentials !== newRow.credentials ||
        oldRow.coachProfile?.bio !== newRow.bio)
    ) {
      updateUnsavedChanges(
        newRow.id,
        newRow.manager,
        newRow.startDate,
        newRow.endDate,
        newRow.stateLicences,
        newRow.getHealthieId,
        newRow.schedulingPriority,
        newRow.outOfOffice,
        newRow.credentials,
        newRow.bio
      )
    } else {
      resetUnsavedChanges(newRow.id)
    }

    return newRow
  }

  const gridDataWithPendingChanges = initialData.map((nutritionist) => {
    let manager = nutritionist.coachProfile?.manager
    let startDate = nutritionist.coachProfile?.startDate
    let endDate = nutritionist.coachProfile?.endDate
    let stateLicences = nutritionist.coachProfile?.stateLicences
    let active = nutritionist.coachProfile?.active
    let getHealthieId = nutritionist.coachProfile?.getHealthieId
    let schedulingPriority = nutritionist.coachProfile?.schedulingPriority
    let outOfOffice = nutritionist.coachProfile?.outOfOffice
    let credentials = nutritionist.coachProfile?.credentials
    let bio = nutritionist.coachProfile?.bio

    if (unsavedChanges[nutritionist.id]) {
      manager = unsavedChanges[nutritionist.id].manager
      startDate = unsavedChanges[nutritionist.id].startDate
      endDate = unsavedChanges[nutritionist.id].endDate
      stateLicences = unsavedChanges[nutritionist.id].stateLicences
      getHealthieId = unsavedChanges[nutritionist.id].getHealthieId
      schedulingPriority = unsavedChanges[nutritionist.id].schedulingPriority
      outOfOffice = unsavedChanges[nutritionist.id].outOfOffice
      credentials = unsavedChanges[nutritionist.id].credentials
      bio = unsavedChanges[nutritionist.id].bio
    }

    return {
      id: nutritionist.id,
      fullName: nutritionist.fullName,
      getHealthieId,
      email: nutritionist.email,
      role: nutritionist.role,
      manager,
      startDate,
      endDate,
      schedulingPriority,
      stateLicences: stateLicences,
      active,
      updatedAt: nutritionist.coachProfile?.updatedAt,
      priorityChangedAt: nutritionist.coachProfile?.priorityChangedAt,
      outOfOffice,
      credentials,
      bio
    }
  })

  const columns = getNutritionistsViewColumns()

  const actions = {
    field: 'actions',
    headerName: 'Actions',
    type: 'actions',
    getActions: (params: GridRowParams) => {
      if (params.row.active) {
        return [
          <GridActionsCellItem
            placeholder={undefined}
            key="deactivate"
            label="Deactivate"
            icon={<CancelIcon color="error" />}
            onResize={() => {}}
            onResizeCapture={() => {}}
            showInMenu
            onClick={() => {
              deactivateCoachProfile({
                variables: {
                  userId: params.row.id
                }
              })
            }}
          />
        ]
      } else {
        return [
          <GridActionsCellItem
            placeholder={undefined}
            key="activate"
            label="Activate"
            icon={<CheckIcon color="success" />}
            onResize={() => {}}
            onResizeCapture={() => {}}
            showInMenu
            onClick={() => {
              bulkUpdateCoachProfiles({
                variables: {
                  coachProfiles: [
                    {
                      userId: params.row.id,
                      state: EhrCoachState.Active
                    }
                  ]
                }
              })
            }}
          />
        ]
      }
    }
  }

  const columnsWithActions = [...columns, actions]

  return (
    <ServerDataGrid
      rows={gridDataWithPendingChanges}
      columns={columnsWithActions}
      stateKey={NUTRITIONISTS_DATA_GRID_STATE_KEY}
      ref={dataGridRef}
      rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
      gridVars={nutritionistsGridVars}
      rowCount={usersData?.allUsers.totalCount}
      isCellEditable={isCellEditable}
      refresh={refetch}
      loading={loading || deactivatingCoachProfile}
      error={error}
      processRowUpdate={processRowUpdate}
      toolbarAdornments={
        !isEmpty(unsavedChanges) ? (
          <BulkUpdateNutritionists
            bulkSaveChanges={bulkSaveChanges}
            discardChanges={discardChanges}
            savingChanges={savingChanges}
          />
        ) : null
      }
    />
  )
}
