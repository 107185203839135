import { useMemo, useRef, useState } from 'react'
import { Box, MenuItem } from '@mui/material'
import { GridCellParams } from '@mui/x-data-grid'
import { DataGrid as ServerDataGrid, DataGridHandle } from 'components/DataGrid'
import { snackBarVisibleVar } from 'context'
import { coachAssignmentGridVars } from 'context/adminPanel'
import { useAuthorizedFeature, useFetchDataOnLocationChange } from 'hooks'
import { LocationPaths } from 'location.types'
import { isEmpty } from 'lodash'
import { BulkUpdateCoachAssignment } from 'screens/AdminPanel/CoachAssignmentDataGrid'
import {
  COACH_ASSIGNMENT_DATA_GRID_STATE_KEY,
  COACH_ASSIGNMENT_GRID_QUICK_FILTERS_DEFINITIONS,
  getFilteredColumns
} from 'screens/AdminPanel/CoachAssignmentDataGrid/constants'
import { COLUMNS } from 'screens/AdminPanel/CoachAssignmentDataGrid/types'
import { getStripeRowsClassName } from 'utility/dataGrid'
import { getCommonListQueryVariables, useReactiveGridVars } from 'utility/dataGrid/commonVariables'
import {
  useBulkCoachAssignMutation,
  IUser,
  CoachAssignmentState,
  IQueryType,
  useAdminPanelAllCoachAssignmentsQuery,
  useBulkFollowCoachAssignmentsMutation,
  useBulkUnfollowCoachAssignmentsMutation,
  CoachAssignmentSupportType,
  HealthPortalFeature
} from 'types'

const ROWS_PER_PAGE_OPTIONS = [5, 10, 25, 50, 100, 150, 250]
const EDITABLE_SUPPORT_TYPES = [
  CoachAssignmentSupportType.AsyncChat,
  CoachAssignmentSupportType.VideoChat,
  CoachAssignmentSupportType.MealPlan,
  CoachAssignmentSupportType.SupplementReview
]

export const CoachAssignmentDataGrid = () => {
  const dataGridRef = useRef<DataGridHandle>(null)

  const reactiveGridVars = useReactiveGridVars(coachAssignmentGridVars)

  const coachAssignmentEnabled = useAuthorizedFeature(
    HealthPortalFeature.AdminPanelManageNutritionistAssignment
  )

  const [unsavedCoachAssignments, setUnsavedCoachAssignments] = useState(
    {} as {
      [id: string]: {
        userId: string
        coachId: string
        coachFullName: string
        supportType: CoachAssignmentSupportType
      }
    }
  )
  const [selectedCoachId, setSelectedCoachId] = useState<string | null>(null)
  const [gridKey, setGridKey] = useState(Date.now)
  const discardCoachAssignmentChanges = () => {
    setUnsavedCoachAssignments({})
    setGridKey(Date.now)
  }

  const updateUnsavedCoachAssignment = (
    id: string,
    userId: string,
    newCoach: IUser,
    supportType: CoachAssignmentSupportType
  ) => {
    setUnsavedCoachAssignments((prevState) => ({
      ...prevState,
      [id]: { userId, coachId: newCoach.id, coachFullName: newCoach.fullName, supportType }
    }))
  }

  const changeCoachInjectedProps = useMemo(
    () => ({
      refetchQueryName: 'AdminPanelAllCoachAssignments' as keyof IQueryType,
      updateUnsavedCoachAssignment,
      unsavedCoachAssignments
    }),
    [unsavedCoachAssignments]
  )

  const filteredColumns = useMemo(
    () =>
      getFilteredColumns({
        changeCoachInjectedProps,
        coachAssignmentEnabled
      }),
    [changeCoachInjectedProps, coachAssignmentEnabled]
  )

  const {
    data: coachAssignmentData,
    loading,
    error,
    refetch
  } = useAdminPanelAllCoachAssignmentsQuery({
    variables: {
      ...getCommonListQueryVariables(reactiveGridVars),
      last: true
    }
  })

  const [saveCoachAssignmentChanges, { loading: savingCoachAssignments }] =
    useBulkCoachAssignMutation({
      onError: (error) => {
        snackBarVisibleVar({
          open: true,
          message: error.message,
          handleClose: discardCoachAssignmentChanges
        })
      },
      onCompleted: () => setUnsavedCoachAssignments({}),
      refetchQueries: ['AdminPanelAllCoachAssignments', 'AllHealthPortalNotifications']
    })

  const [followCoachAssignmentsMutation] = useBulkFollowCoachAssignmentsMutation({
    onError: (error) => {
      snackBarVisibleVar({
        open: true,
        message: error.message
      })
    },
    onCompleted: () =>
      snackBarVisibleVar({
        open: true,
        severity: 'success',
        message: 'Follow successful'
      })
  })

  const [unfollowCoachAssignmentsMutation] = useBulkUnfollowCoachAssignmentsMutation({
    onError: (error) => {
      snackBarVisibleVar({
        open: true,
        message: error.message
      })
    },
    onCompleted: () =>
      snackBarVisibleVar({
        open: true,
        severity: 'success',
        message: 'Unfollow successful'
      })
  })

  const followCoachAssignments = async () =>
    selectedCoachId &&
    (await followCoachAssignmentsMutation({ variables: { coachId: selectedCoachId } }))
  const unfollowCoachAssignments = async () =>
    selectedCoachId &&
    (await unfollowCoachAssignmentsMutation({ variables: { coachId: selectedCoachId } }))

  const bulkSaveCoachAssignmentChanges = () => {
    saveCoachAssignmentChanges({
      variables: {
        coachAssignments: Object.keys(unsavedCoachAssignments).map((id) => ({
          id: id,
          userId: unsavedCoachAssignments[id].userId,
          coachId: unsavedCoachAssignments[id].coachId,
          supportType: unsavedCoachAssignments[id].supportType
        }))
      }
    })
  }

  const coachAssignments = coachAssignmentData?.allCoachAssignments.coachAssignments || []

  useFetchDataOnLocationChange(refetch, LocationPaths.AdminPanel)

  const isCellEditable = ({ field, row }: GridCellParams<any, any>) => {
    if (field !== COLUMNS.COACH) return true
    if (!EDITABLE_SUPPORT_TYPES.includes(row.supportType)) return false
    return (
      [CoachAssignmentState.Pending, CoachAssignmentState.Active] as (
        | CoachAssignmentState
        | undefined
      )[]
    ).includes(row?.state)
  }

  const additionalActions = (
    <Box>
      <MenuItem onClick={followCoachAssignments}>Follow Members</MenuItem>
      <MenuItem onClick={unfollowCoachAssignments}>Unfollow Members</MenuItem>
    </Box>
  )

  return (
    <ServerDataGrid
      rows={coachAssignments}
      key={gridKey}
      columns={filteredColumns}
      loading={loading}
      error={error}
      stateKey={COACH_ASSIGNMENT_DATA_GRID_STATE_KEY}
      ref={dataGridRef}
      isCellEditable={isCellEditable}
      gridVars={coachAssignmentGridVars}
      rowHeight={undefined}
      refresh={refetch}
      rowCount={coachAssignmentData?.allCoachAssignments.totalCount}
      quickFilterDefinitions={COACH_ASSIGNMENT_GRID_QUICK_FILTERS_DEFINITIONS}
      rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
      getRowClassName={(params) => {
        if (unsavedCoachAssignments[params.row?.user?.id]) {
          return 'dirty'
        }
        return getStripeRowsClassName(params)
      }}
      toolbarAdornments={
        !isEmpty(unsavedCoachAssignments) ? (
          <BulkUpdateCoachAssignment
            bulkSaveCoachAssignmentChanges={bulkSaveCoachAssignmentChanges}
            discardCoachAssignmentChanges={discardCoachAssignmentChanges}
            savingCoachAssignments={savingCoachAssignments}
          />
        ) : null
      }
      additionalActions={selectedCoachId && additionalActions}
      onStateChange={(state) => {
        const items = state.filter.filterModel.items
        setSelectedCoachId(
          (items.length === 1 &&
            items[0].columnField === COLUMNS.COACH &&
            items[0].operatorValue === 'is' &&
            items[0].value) ||
            null
        )
      }}
    />
  )
}
