import { GridState } from '@mui/x-data-grid'
import { DATA_GRID_STATE_KEY as APPROVAL_REQUESTS_STATE_KEY } from 'screens/Approvals/ApprovalRequests/constants'
import { QUICK_FILTERS_DEFINITIONS as APPROVAL_REQUESTS_DEFINITIONS } from 'screens/Approvals/ApprovalRequests/constants'
import { DATA_GRID_STATE_KEY as BILLING_DASHBOARD_CHARGES_STATE_KEY } from 'screens/BillingDashboard/Charges/constants'
import { DATA_GRID_STATE_KEY as BILLING_DASHBOARD_INVOICES_STATE_KEY } from 'screens/BillingDashboard/Invoices/constants'
import { DATA_GRID_STATE_KEY as BILLING_DASHBOARD_SUBSCRIPTIONS_STATE_KEY } from 'screens/BillingDashboard/Subscriptions/constants'
import { ApprovalRequestSortField } from 'types'
import { PER_PAGE_OPTIONS } from './constants'

export const DEFAULT_STATE = {
  pagination: { pageSize: PER_PAGE_OPTIONS[2] }
} as GridState

const APPROVAL_REQUESTS_DEFAULT_STATE = {
  pagination: { pageSize: PER_PAGE_OPTIONS[2] },
  filter: {
    filterModel: {
      items: APPROVAL_REQUESTS_DEFINITIONS[0].items,
      linkOperator: 'and'
    }
  },
  sorting: {
    sortModel: [
      {
        field: ApprovalRequestSortField.State,
        sort: 'desc'
      }
    ]
  }
} as GridState

const BILLING_DASHBOARD_DEFAULT_STATE = {
  pagination: { pageSize: PER_PAGE_OPTIONS[0] }
} as GridState

const DEFAULT_STATES = {
  [APPROVAL_REQUESTS_STATE_KEY]: APPROVAL_REQUESTS_DEFAULT_STATE,
  [BILLING_DASHBOARD_SUBSCRIPTIONS_STATE_KEY]: BILLING_DASHBOARD_DEFAULT_STATE,
  [BILLING_DASHBOARD_INVOICES_STATE_KEY]: BILLING_DASHBOARD_DEFAULT_STATE,
  [BILLING_DASHBOARD_CHARGES_STATE_KEY]: BILLING_DASHBOARD_DEFAULT_STATE
}

export const defaultState = (stateKey: string) =>
  DEFAULT_STATES[stateKey as keyof typeof DEFAULT_STATES] || DEFAULT_STATE
