import { useModal } from 'hooks'
import { IChangeValueGridInjectedProps } from 'screens/NutritionistsOrganization/NutritionistsGrid/ChangeManager'
import { ChangeLicencesModal } from './ChangeLicencesModal'

export const ChangeLicences = (props: IChangeValueGridInjectedProps) => {
  const { rowId, field, userId } = props

  const { closeModal: closeChangeLicencesModal } = useModal()

  return (
    <ChangeLicencesModal
      rowId={rowId}
      field={field}
      userId={userId}
      closeModal={closeChangeLicencesModal}
      modalState
    />
  )
}
